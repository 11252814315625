<template>
  <section id="dashboard-reports">
    <b-card>
      <b-form-datepicker
        id="datePicker"
        v-model="selectDate"
        class="mb-1"
        @input="updateData"
      />
    </b-card>
    <b-row class="match-height">
      <b-col
        lg="8"
        class="order-2 order-lg-1"
      >
        <ReportsTable
          :table-data="reports"
          :date="selectDate"
        />
      </b-col>
      <b-col
        lg="4"
        class="order-1 order-lg-2"
      >
        <ReportsStatistics
          :data="countData"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BFormDatepicker, BCard,
} from 'bootstrap-vue'
import store from '@/store'
import { mapGetters } from 'vuex'
import { add } from 'mathjs'
import ReportsTable from './components/ReportsTable.vue'
import ReportsStatistics from './components/ReportsStatistics.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    ReportsTable,
    ReportsStatistics,
  },
  /* eslint-disable global-require */
  data() {
    return {
      selectDate: this.$moment().format('YYYY-MM-DD'),
      reports: [],
      countData: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  async mounted() {
    await this.setData()
  },
  methods: {
    updateData() {
      this.setData()
    },
    async setData() {
      await store.dispatch('transactionsReport', { ...this.user, date: this.selectDate }).then(transactions => {
        this.reports = transactions
        let count = 0
        let loan = 0
        transactions.forEach(trans => {
          count = +add(count, trans.count)
          loan = +add(loan, trans.amount)
        })
        this.countData = [
          {
            mode: this.$t('total loan'),
            types: 'Total Loan Amount',
            avatar: 'DollarSignIcon',
            avatarVariant: 'light-danger',
            payment: loan,
            deduction: true,
          },
          {
            mode: this.$t('total loans'),
            types: 'Total Loans',
            avatar: 'TrendingUpIcon',
            avatarVariant: 'light-info',
            payment: count,
            deduction: false,
          },
        ]
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
