<template>
  <b-card
    v-if="tableData"
    no-body
    class="overflow-hidden"
  >
    <h5 class="mt-1 ml-2 mb-1">
      {{ `${$t('sales report')} ${$moment(date).subtract(30, 'd').format('YYYY-MM-DD')} - ${$moment(date).format('YYYY-MM-DD')}` }}
    </h5>
    <b-table
      id="report-table"
      :items="tableData"
      responsive
      striped
      hover
      :fields="fields"
      table-class="text-nowrap"
    >
      <!-- revenue -->
      <template #cell(amount)="data">
        {{ `$ ${numberWithCommas(data.value)}` }}
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: new Date(),
    },
  },
  data() {
    return {
      fields: [
        { key: 'date', label: this.$t('date') },
        { key: 'count', label: this.$t('loan') },
        { key: 'amount', label: this.$t('total loan') },
        { key: 'userName', label: this.$t('user') },
      ],
    }
  },
}
</script>
